@use "sass/abstracts/variables";
@use 'sass/abstracts/mixins';

.page-rs {
    padding: 20px 26px !important;
    height: 100%;
  
    #currentStaffing, #employeeList {
      align-content: flex-start;
    }
  
    .rs-left {
      height: 100%;
      border: 1px solid variables.$grey-300;
      border-top: none;
      border-right: none;
      border-collapse: collapse;
      box-sizing: border-box;
      margin-right: 13px;
    }
  
    .item-gray {
      color: variables.$grey-300 !important;
      cursor: not-allowed !important;
    }
  }
  
  .rs-left .rs-filter {
    height: 69px;
    padding: 5px 8px 0px 9px;
    width: 10%;
    position: absolute;
    background-color: white;
    z-index: 2;
    border-top: 1px solid variables.$grey-300;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .page-rs {
    .rs-searchbox {
      font-family: variables.$main-font-family !important;
      font-style: normal !important;
      height: 28px;
      width: 100%;
      flex-direction: row-reverse;
      box-sizing: border-box;
      border: 1px solid variables.$grey-300;
    }
  
    .ms-SearchBox-iconContainer {
      width: 32px !important;
    }
  
    .rs-searchbox {
      .ms-SearchBox-icon {
        color: variables.$grey-300;
      }
  
      .ms-SearchBox-field {
        padding: 5px;
  
        &::placeholder {
          color: variables.$grey-300;
        }
      }
    }
  
    .ms-Dropdown {
      height: 28px;
      padding: 5px 0px 0px 9px;
      margin: 4px 0px 4px;
  
      .ms-Dropdown-title {
        font-family: variables.$main-font-family !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        padding: 0px 20px 0px 0px;
        margin: 0px;
        height: auto;
        background-color: transparent !important;
      }
    }
  }
  
  .rs-dropdown .ms-Dropdown-optionText {
    font-family: variables.$main-font-family !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
  }
  
  #rs_resource_datatable {
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  /*schedule pop up - begin*/
  
  .rs-dialog-schedule {
    width: 669px !important;
    height: 607px !important;
    position: absolute;
    background-color: variables.$white;
    border: variables.$black solid 1px;
    left: 175px;
    top: 92px;
    padding: 22px 20px;
    z-index: 1999;
  
    .rs-dialog-form {
      border: 2px solid variables.$wds-orange;
      border-radius: 5px;
      height: 560px;
      overflow-y: auto;
      overflow-x: hidden;
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: variables.$black;
      padding: 5px 20px 20px 13px;
    }
  
    .custom-input {
      width: 100% !important;
    }
  
    input[type="text"], select, .ms-Dropdown {
      height: 35px;
      width: 162px;
    }
  
    .ms-Dropdown-container .is-disabled {
      background-color: variables.$grey-200 !important;
    }
  
    .ms-Dropdown {
      padding: 8px 0px 0px 9px !important;
    }
  
    .react-datepicker-wrapper input[type='text'] {
      width: 140px !important;
    }
  
    .rs-icon-calendar {
      padding: 5px 8px;
      background: variables.$wds-orange;
  
      > i::before {
        font-weight: bold !important;
      }
    }
  
    .ms-Checkbox-text {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: variables.$black;
    }
  
    .ms-Checkbox-checkbox {
      border: 1px solid variables.$grey-300;
    }
  
    .is-checked .ms-Checkbox-checkbox {
      background-color: variables.$black !important;
      border-color: variables.$black !important;
    }
  
    .form-check {
      display: flex;
      align-items: center;
    }
  
    .form-check-input {
      width: 20px !important;
      height: 20px !important;
      margin-right: 5px;
      margin-top: 0px;
    }
  
    .form-check-label {
      font-weight: 400 !important;
    }
  
    .form-check-input:checked {
      background-color: variables.$black;
      border-color: variables.$black;
    }
  
    .rs-dialog-title {
      margin-top: 22px;
      padding-bottom: 5px;
      padding-left: 10px;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: self-end;
      border-bottom: 1px solid variables.$black;
    }
  
    .rs-dialog-form .rs-dialog-title:not(:first-child) {
      margin-top: 8px;
    }
  
    .rs-dialog-content {
      padding: 6px 23px 5px 0px;
      align-items: center;
      width: 100%;
  
      > {
        div, textarea {
          width: 100%;
          margin-top: 9px;
        }
  
        .d-flex > div {
          align-self: center !important;
        }
      }
    }
  
    .rs-dialog-label {
      padding-left: 10px;
      display: flex;
      align-items: center;
      white-space: nowrap;
      word-break: keep-all;
    }
  
    .rs-dialog-content .rs-dialog-label:nth-child(odd), .rs-bottom-btns {
      padding-left: 20px;
    }
  
    .rs-dialog-content .rs-content-textarea {
      border: 1px solid variables.$grey-300;
      overflow-x: hidden;
      overflow-y: auto;
      height: 82px;
      margin-left: 20px;
    }
  
    .rs-top-btns {
      margin: 0px -4px 10px 0px;
    }
  
    .rs-bottom-btns {
      margin: 23px 0px 0px 0px;
    }
  
    .rs-top-btns input[type='button'], .rs-bottom-btns input[type='button'] {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: variables.$black;
    }
  
    .rs-top-btns input[type='button']:not(:first-child), .rs-bottom-btns input[type='button']:not(:first-child) {
      margin-left: 9px;
    }
  }
  
  .rs-current-staffing-disabled {
    cursor: not-allowed !important;
    color: variables.$grey-300;
  }
  
  .rs-current-staffing {
    .rs-employee-item {
      width: 130px;
      height: 26px;
      margin: 3px 0px 0px 1px !important;
      padding: 6px 4px;
      background: variables.$green-100;
      border: 1px solid variables.$green-300;
      border-radius: 30px;
  
      .rs-wds-icon, .rs-alliance-icon {
        display: none;
      }
    }
  
    .rs-employee-del {
      display: inline;
      float: right;
      margin-top: -4px;
      cursor: pointer;
    }
  
    .ms-Persona {
      align-items: flex-start !important;
      display: inline-flex !important;
      max-width: 100px !important;
      text-overflow: ellipsis;
    }
  
    .ms-Persona-coin {
      margin-right: 2px !important;
    }
  
    .ms-Persona-image, .ms-Persona-coin, .ms-Persona-imageArea, .ms-Persona-initials {
      width: 20px !important;
      height: 20px !important;
    }
  
    .ms-Persona-image, .ms-Persona-imageArea {
      margin-top: -4px !important;
    }
  
    .ms-Persona-initials > span {
      position: absolute !important;
      top: 2px !important;
      left: 2px !important;
      line-height: 14px !important;
      margin-right: 2px !important;
    }
  
    .ms-Persona-secondaryText {
      display: none !important;
    }
  
    .ms-Persona-details {
      padding: 0px !important;
    }
  }
  
  .page-rs .rs-right .ms-Persona-details {
    padding: 0px 0px 0px 5px !important;
  }
  
  .rs-current-staffing .ms-Persona-coin > i {
    top: -3px !important;
  }
  
  .rs-dialog-schedule {
    .rs-staffing-his, .rs-ro-his {
      margin: 7px 8px 7px 4px;
    }
  
    .rs-staffing-his > div, .rs-ro-his > div {
      padding-left: 10px;
    }
  
    .rs-staffing-his > div:nth-child(even), .rs-ro-his > div:nth-child(odd) {
      background-color: variables.$grey-200;
    }
  
    .rs-staffing-his {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: variables.$black;
    }
  
    .rs-ro-his {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: variables.$black;
  
      .font-bold {
        font-weight: 700 !important;
      }
    }
  
    .react-datepicker-popper {
      z-index: 2 !important;
    }
  
    .error {
      color: variables.$red;
    }
  }
  
  .page-rs {
    .ms-Dropdown-callout {
      max-height: 300px !important;
    }
  
    .fc {
      .allianceHeaderGroup {
        :nth-of-type(1) {
          background-color: variables.$wds-orange !important;
          border-color: variables.$wds-orange !important;
        }
        span {
          margin-top: 9px;
          margin-left: 3px;
        }
      }
      .fc-header-toolbar {
        height: 36px !important;
        margin: 0px 0px 0px 0px !important;
        background: variables.$wds-orange !important;
      }
  
      .fc-popover {
        z-index: 8 !important;
      }
  
      .fc-button-primary {
        font-family: variables.$main-font-family !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: variables.$black;
        background-color: transparent;
        border-width: 0px;
        box-shadow: none;
  
        &:hover, &:focus, &:active {
          font-family: variables.$main-font-family !important;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: variables.$black;
          background-color: transparent;
          border-width: 0px;
          box-shadow: none;
        }
  
        &:not(:disabled) {
          &:active, &.fc-button-active {
            font-family: variables.$main-font-family !important;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: variables.$black;
            background-color: transparent;
            border-width: 0px;
            box-shadow: none;
          }
        }
      }
  
      .fc-toolbar-title {
        display: inline-block;
        height: 19px;
        font-family: variables.$main-font-family !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: variables.$black;
        vertical-align: middle;
      }
  
      .fc-view .fc-scrollgrid {
        border-width: 0px;
      }
  
      .fc-scrollgrid thead .fc-scroller {
        margin-bottom: 0px !important;
        overflow-x: hidden !important;
      }
  
      .fc-resource-timeline-divider {
        width: 1px !important;
        border: none;
        background-color: variables.$white;
      }
  
      .fc-timeline-slot-cushion {
        font-family: variables.$main-font-family !important;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: variables.$black;
        text-decoration: none;
      }
  
      .fc-timeline-slot-frame, .fc-datagrid-cell .fc-datagrid-cell-frame {
        height: 34px !important;
      }
    }
  
    .rs-calendar-week .fc-resource {
      .fc-datagrid-cell-frame, .fc-timeline-lane-frame {
        height: 120px !important;
      }
    }
  
    .rs-calendar-month .fc-resource {
      .fc-datagrid-cell-frame, .fc-timeline-lane-frame {
        height: 60px !important;
      }
    }
  
    .fc {
      .fc-datagrid-cell-cushion {
        padding: 0px;
        height: 100%;
      }
  
      .fc-datagrid-cell-main, .engine-row, .engine-row-selected {
        height: 100%;
      }
  
      .engine-row {
        padding: 8px;
        border-top: 1px solid variables.$grey-300;
        height: 120px;
        cursor: pointer;
      }
  
      .engine-row-selected {
        z-index: 999 !important;
        padding: 8px;
        border: 1px solid variables.$black !important;
        border-right-width: 0px !important;
        background-color: variables.$white;
        position: relative;
      }
  
      .engine-row:first-child {
        border-top-width: 0px;
      }
  
      .engine-header {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        white-space: pre-wrap;
        word-break: break-all;
      }
  
      .engine-content {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
      }
  
      .fc-highlight {
        background: variables.$grey-200;
        border: 1px solid variables.$green-300;
      }
  
      .fc-scrollgrid-section-body table {
        border-bottom-style: initial;
      }
    }
  
    .rs-right {
      width: 40px;
      height: 100%;
      border: 1px solid variables.$grey-300;

      .employee-header {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 8px 0px;
        background: variables.$wds-orange !important;
        font-family: variables.$main-font-family !important;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: variables.$black;

        .employee-list-header {
          display: flex;
          flex: 4;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          writing-mode: vertical-rl;
        }
      }

      .employee-items {
        display: none;
      }
  
      .ep-filter {
        display: none;
      }
    }
  
    .rs-right-expand {
      width: 219px !important;

      .employee-header {
        height: 36px !important;
        margin: 0px !important;
        padding: 8px 13px;
        text-align: center;
        flex-direction: row;

        .employee-list-header {
          writing-mode: horizontal-tb;
          flex-direction: row;
          align-items: normal;
        }
      }

      .ep-filter {
        padding: 5px 35px 10px 9px;
        line-height: 35px;
        padding: 5px 28px 0px 9px;
        width: 100%;
        display: block;
      }

      .employee-items {
        display: block;
      }
  
      .ms-SearchBox {
        max-width: 190px;
      }
  
      .ms-Dropdown {
        margin: 0px;
      }
    }
  
    .rs-right .employee-items {
      height: 570px;
      overflow-y: auto;
      overflow-x: auto;
    }
  
    .rs-right-expand .employee-items {
      height: 570px;
    }
  }
  
  /*schedule pop up - end*/
  
  /*full calendar - begin*/
  
  /*full calendar - end*/
  /*employee list - begin*/
  
  .rs-staffing-his {
    .rs-employee-item {
      display: none !important;
    }
  
    .rs-staffing-his-item {
      cursor: pointer;
    }
  }
  
  .rs-current-staffing {
    .rs-staffing-his-item {
      display: none;
    }
  
    .gu-mirror-gray {
      background: transparent;
      border: none;
    }
  }
  
  .rs-staffing-his {
    .gu-mirror-gray {
      background: transparent;
      border: none;
    }
  
    .rs-employee-item {
      width: 185px;
      height: 38px;
      margin: 3px 12px 0px 12px;
      padding: 4px 8px;
      background: variables.$green-100;
      border: 1px solid variables.$green-300;
      border-radius: 30px;
      cursor: pointer;
    }
  }
  
  .page-rs .rs-right {
    .rs-employee-item {
      width: 185px;
      height: 38px;
      margin: 3px 12px 0px 12px;
      padding: 4px 8px;
      background: variables.$green-100;
      border: 1px solid variables.$green-300;
      border-radius: 30px;
      cursor: pointer;
    }
  
    .ms-Persona-imageArea {
      margin-top: 2px;
    }
  }
  
  .rs-current-staffing .rs-employee-item-gray, .rs-staffing-his .rs-employee-item-gray, .page-rs .rs-right .rs-employee-item-gray {
    background: variables.$grey-100 !important;
    border: 1px solid variables.$grey-300;
  }
  
  .rs-border-dotted {
    border: 2px dotted variables.$grey-300 !important;
  }
  
  .rs-staffing-his {
    .rs-staffing-his-item-not, .rs-employee-item-not {
      cursor: not-allowed;
    }
  }
  
  .page-rs .rs-right .rs-employee-item-not {
    cursor: not-allowed;
  }
  
  .rs-staffing-his .ms-Persona, .page-rs .rs-right .ms-Persona {
    align-items: flex-start !important;
    display: inline-flex !important;
  }
  
  .rs-staffing-his .rs-wds-icon, .page-rs .rs-right .rs-wds-icon {
    float: right;
    display: inline;
    margin-top: 7px !important;
    width: 14px;
    height: 15px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI1SURBVHgBjVI5bxNREP727Xod3469wSDsWCBxSRFHOISEAuYsKOgAIVFGQkL8Bwp+ABIUNBSkTQMRR4FScBRAAkGQFIkgJiF2bOMzsb22316MvUowoEj5inlPM/O9+WbeCOhC+fmZeO4Nbnv7hataGW7Jg8V62nrgOda4F7vyrtGdK6xdZm6cPuDaxsbDg1B4hQIiIDrtjEYWc/4dfMh38W1+LZ+1jfoiEWVONqYchcIkoPARyLwCSl8AyQU4w9hT/CqPdlfsEJfGxTtbhxAX6PXCFJCfpEKCfVYXAdkPuCJI5EbOXl8nqk8uxOQ+XKN+OtBWSdpODRMLKsoKh9my/T0KKUtZw+vEn6+1QdcWOKsLgMEBywICBQe+VVrYLssolUyoywCnByW3cGp+9FygQwwfEQ6Jro4UZKmvOjeRklqIREQUHRq4amE1SXJ9JF8GemVrf4dYS5GlKpZB/XADzRmGXYoTFaYjZErgUyIEhz3dnhDJzRsRezi6rbn6HYgdFMGOa0g3OCxmIaVq6LtkwB8H9Jo9sL+m2oZJFfkKOaYdkDSGZJMj0JJQ/ySitmTH/vuONpTDdp/BvcDuywJW6gYi5y14Y9QX+Tz9GxDbePS+jJFfBTDaGq+boSkbuDubw9PpKv6F1DaZoo6HYyU8+2AnTM420NJM3LyfwTLFXn6u4UeGY/hk7x+iTh88n9QxQLs1cMKFDUErnp4zYfgMfzQaDQnlx4kguYPYJLJptbrv1kTpN3YP06a789+iAAAAAElFTkSuQmCC');
  }
  
  .rs-staffing-his .rs-alliance-icon {
    float: right;
    display: inline;
    margin-top: 7px;
    width: 14px;
    height: 15px;
  }
  
  .page-rs {
    .rs-right {
      .rs-alliance-icon {
        float: right;
        display: inline;
        margin-top: 7px;
        width: 14px;
        height: 15px;
      }
  
      .rs-employee-item > div:not(:first-child) {
        margin-top: 3px;
      }
    }
  
    .rs-right-expand .rs-employee-item {
      margin: 3px 6px 0px 6px;
    }
  }
  
  /*employee list - end*/
  
  /* drag - begin */
  
  .gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
  }
  
  /*source element*/
  
  .gu-transit {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity = 80);
  }
  
  /*mirror image*/
  
  .gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    padding: 5px;
    width: 185px !important;
    height: 40px !important;
    background: variables.$green-100;
    border: 1px solid variables.$green-300;
    border-radius: 30px;
    cursor: pointer;
  
    .rs-employee-item {
      margin-left: auto !important;
    }
  }
  
  .gu-mirror-gray {
    background: variables.$grey-100;
    border: 1px solid variables.$grey-300;
  
    .rs-employee-item {
      margin-top: 0px !important;
    }
  }
  
  .gu-mirror {
    .ms-Persona {
      align-items: flex-start !important;
      display: inline-flex !important;
    }
  
    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      font-family: variables.$main-font-family !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 12px !important;
      height: 15px !important;
      color: variables.$black !important;
      max-width: 110px;
      text-overflow: ellipsis;
    }
  }
  
  .rs-staffing-his {
    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      font-family: variables.$main-font-family !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 12px !important;
      height: 15px !important;
      color: variables.$black !important;
      max-width: 110px;
      text-overflow: ellipsis;
    }
  }
  
  .page-rs {
    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      font-family: variables.$main-font-family !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 12px !important;
      height: 15px !important;
      color: variables.$black !important;
      max-width: 110px;
      text-overflow: ellipsis;
    }
  }
  
  .rs-employee-alliance {
    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      max-width: 120px !important;
    }
  }
  
  .page-rs .fst-italic {
    .ms-Persona-primaryText, .ms-Persona-secondaryText {
      font-style: italic !important;
    }
  }
  
  .gu-mirror {
    .rs-wds-icon {
      float: right;
      display: inline;
      margin-top: 7px !important;
      width: 14px;
      height: 15px;
      background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPCAYAAADUFP50AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAI1SURBVHgBjVI5bxNREP727Xod3469wSDsWCBxSRFHOISEAuYsKOgAIVFGQkL8Bwp+ABIUNBSkTQMRR4FScBRAAkGQFIkgJiF2bOMzsb22316MvUowoEj5inlPM/O9+WbeCOhC+fmZeO4Nbnv7hataGW7Jg8V62nrgOda4F7vyrtGdK6xdZm6cPuDaxsbDg1B4hQIiIDrtjEYWc/4dfMh38W1+LZ+1jfoiEWVONqYchcIkoPARyLwCSl8AyQU4w9hT/CqPdlfsEJfGxTtbhxAX6PXCFJCfpEKCfVYXAdkPuCJI5EbOXl8nqk8uxOQ+XKN+OtBWSdpODRMLKsoKh9my/T0KKUtZw+vEn6+1QdcWOKsLgMEBywICBQe+VVrYLssolUyoywCnByW3cGp+9FygQwwfEQ6Jro4UZKmvOjeRklqIREQUHRq4amE1SXJ9JF8GemVrf4dYS5GlKpZB/XADzRmGXYoTFaYjZErgUyIEhz3dnhDJzRsRezi6rbn6HYgdFMGOa0g3OCxmIaVq6LtkwB8H9Jo9sL+m2oZJFfkKOaYdkDSGZJMj0JJQ/ySitmTH/vuONpTDdp/BvcDuywJW6gYi5y14Y9QX+Tz9GxDbePS+jJFfBTDaGq+boSkbuDubw9PpKv6F1DaZoo6HYyU8+2AnTM420NJM3LyfwTLFXn6u4UeGY/hk7x+iTh88n9QxQLs1cMKFDUErnp4zYfgMfzQaDQnlx4kguYPYJLJptbrv1kTpN3YP06a789+iAAAAAElFTkSuQmCC');
    }
  
    > div:not(:first-child) {
      margin-top: 3px;
    }
  }
  
  .gu-hide, .gu-mirror .rs-employee-del, .rs-staffing-his .rs-employee-del, .rs-current-staffing .rs-staffing-his-text, .gu-mirror .rs-staffing-his-text, .page-rs .rs-right .rs-employee-del {
    display: none !important;
  }
  
  /*drag - end*/
  
  /*dialog - begin*/
  
  .rs-employee-conflict-dlg {
    max-width: 700px;
    min-width: 384px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: variables.$black solid 1px;
  }
  
  .rs-delete-schedule-dlg {
    max-width: 400px;
    min-width: 384px;
    height: 166px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  
  .rs-employee-conflict-dlg div[class^='topButton-'], .rs-delete-schedule-dlg div[class^='topButton-'] {
    display: none !important;
  }
  
  .rs-employee-conflict-dlg {
    .ms-Dialog-title {
      font-family: variables.$main-font-family !important;
      padding-top: 10px;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: variables.$black;
      background-color: variables.$wds-orange;
      height: 40px;
    }
  
    .ms-Dialog-content {
      font-family: variables.$main-font-family !important;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: variables.$black;
      margin: 20px 0px;
    }
  }
  
  .rs-delete-schedule-dlg .ms-Dialog-title {
    font-family: variables.$main-font-family !important;
    padding: 35px 16px 27px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: variables.$black;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-header, .rs-delete-schedule-dlg .ms-Dialog-header {
    margin: 0px;
    display: block;
  }
  
  .rs-employee-conflict-dlg .ms-Modal-scrollableContent div, .rs-delete-schedule-dlg .ms-Modal-scrollableContent div {
    overflow: hidden;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
  }
  
  .rs-delete-schedule-dlg .ms-Dialog-actionsRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-action, .rs-delete-schedule-dlg .ms-Dialog-action {
    margin: 0px 9px;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-action button {
    width: 140px;
    height: 36px;
    border-radius: 5px;
    border-width: 0px;
  }
  
  .rs-delete-schedule-dlg .ms-Dialog-action button {
    width: 110px;
    height: 36px;
    border-radius: 5px;
    border-width: 0px;
  }
  
  .rs-employee-conflict-dlg .ms-Button-label, .rs-delete-schedule-dlg .ms-Button-label {
    font-family: variables.$main-font-family !important;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-actions, .rs-delete-schedule-dlg .ms-Dialog-actions {
    margin: 0px !important;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-action:nth-child(1) button, .rs-delete-schedule-dlg .ms-Dialog-action:nth-child(1) button {
    background: variables.$grey-300;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-action:nth-child(1) .ms-Button-label, .rs-delete-schedule-dlg .ms-Dialog-action:nth-child(1) .ms-Button-label {
    color: variables.$black;
  }
  
  .rs-employee-conflict-dlg .ms-Dialog-action:nth-child(2) button, .rs-delete-schedule-dlg .ms-Dialog-action:nth-child(2) button {
    background: variables.$wds-orange;
  }
  
  .rs-dialog-dropdown {
    max-height: 350px !important;
  
    .ms-Checkbox-label {
      .ms-Checkbox-checkbox {
        border-color: variables.$black !important;
      }
  
      .ms-Dropdown-optionText {
        color: variables.$black !important;
        font-size: 16px !important;
        font-weight: normal !important;
      }
    }
  }
  
  /*dialog - end*/
  
  /* Filters callout */
  
  #rs-filter-callout {
    padding: 15px;
    background: variables.$white;
    min-width: 320px;
    min-height: 500px;
    border-top: 8px solid variables.$wds-orange;
  }
  
  .rs-filter-assignment-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filter-type-section {
    margin-left: 15px;
  }
  
  #rs-filter-callout h5 {
    text-decoration: underline;
  }
  
  .rs-filter-checkbox {
    margin-left: 8px;
    margin-bottom: 5px;
  
    .ms-Checkbox-checkbox {
      width: 20px;
      height: 20px;
    }
  
    &.is-checked {
      .ms-Checkbox-checkbox {
        background: variables.$wds-orange !important;
      }
  
      .ms-Checkbox-checkmark {
        color: variables.$black;
      }
    }
  }
  
  .rs-filter-sort-by {
    margin: 20px 0;
  
    .ms-ChoiceField {
      margin-top: 0;
    }
  }
  
  .rs-filter-buttons {
    display: flex;
    justify-content: flex-end;
  }
  
  .rs-filter-apply {
    margin-left: 10px;
    background: variables.$wds-orange;
    color: variables.$black;
    border: 1px solid variables.$wds-orange;
  
    &:hover {
      background: variables.$wds-orange;
      color: variables.$black;
    }
  }
  
  .rs-filter-sort-by .is-checked {
    &::before {
      border-color: variables.$wds-orange;
    }
  
    &::after {
      background: variables.$wds-orange;
      border-color: variables.$wds-orange;
    }
  }
  
  .fc-toolbar .fc-toolbar-chunk:nth-of-type(2) {
    margin-left: 340px;
  }
  
  .resource-scheduling-kpi-box {
    padding: 0 0px;
  }
  
  .resource-scheduling-last-update {
    @include mixins.kpi-last-update();
  }